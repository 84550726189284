import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NovenaPrayerList from "../components/novena-prayers/novena-prayer-list"

const NovenaPrayers = () => (
  <Layout>
    <SEO title="Novena Prayers" />
    <NovenaPrayerList />
  </Layout>
)

export default NovenaPrayers
