import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import reflectionTitles from "../../data/novena/reflections/titles.json"
import BlessedTrinityImage from "../../images/blessed-trinity.jpg"

const NovenaPrayerList = () => {
  const data = useStaticQuery(graphql`
    query NovenaPrayerListData {
      allSitePage(filter: { path: { regex: "/^/novena-prayers/day/i" } }) {
        nodes {
          path
          context {
            day
          }
        }
      }
    }
  `)

  return (
    <>
      <h2 className="heading">Novena Prayers</h2>
      <div className="novena-prayer-list-container">
        <div className="novena-prayer-list column">
          {data.allSitePage.nodes.map(p => {
            return (
              <p key={p.context.day}>
                <Link to={p.path}>{reflectionTitles[p.context.day]}</Link>
              </p>
            )
          })}
        </div>
        <div className="column">
          <img
            src={BlessedTrinityImage}
            title="Blessed Trinity"
            alt="Blessed Trinity"
          />
        </div>
      </div>
    </>
  )
}

export default NovenaPrayerList
